export default {
    namespaced:true,
    state:{
        productList:null
    },
    mutations:{
        SAVE_PRODUCT_LIST(state,value){
            state.productList = value
        }
    }
}