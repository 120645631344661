import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

import index from './model/index'
import userinfo from './model/userinfo'
import video from './model/video.js'
import videoList from './model/videoList'
import person from './model/person'
import productList from './model/productList'

export default new Vuex.Store({
    modules:{
        index,
        userinfo,
        video,
        videoList,
        person,
        productList
    }
})