<template>
  <div class="root">
    <homeHeader />
    <keep-alive :include="['home']">
      <!-- <transition name="app"> -->
      <router-view></router-view>
      <!-- </transition> -->
    </keep-alive>
    <homeFooter />
  </div>
</template>

<script>
import homeHeader from "./components/homeHeader.vue";
import homeFooter from "./components/homeFooter.vue";
import myStore from './utils/localStore'

export default {
  name: "app",
  components: { homeHeader, homeFooter },
  data() {
    return {
      isLogin:false,
      userinfo:{}
    }
  },
  mounted() {
    this.userinfo =  myStore.getStore('userinfo')
    this.isLogin = myStore.getStore('userinfo')['id']?true:false
    this.loginSocket()
  },
  methods: {
    // 用户已登录 - 注册socket
    loginSocket(){
      // if(this.isLogin){
      //   this.socket.emit('login', this.userinfo.id)
      // }
    } ,

    // 监听 展示新消息
    showNewData(){
        
    }
  },
};
</script>
<style>
.root {
  width: 100%;
  height: 100%;
  /* 隐藏滚动条 */
  overflow: hidden;
}
/* 滚动条 */
/* .root::-webkit-scrollbar {
  display: none;
} */
.app-enter-active {
  animation: changeView 1s;
}
.app-leave-active {
  animation: leaveView 1s reverse;
}

@keyframes enterView {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0%;
  }
}
</style>