import axios from 'axios'
import myStore from '../utils/localStore'
import { baseURL } from "./varites"

export default {
    // 插件引入
    install(vue){
         const axios1 = axios.create({
            baseURL
        })

        axios1.interceptors.request.use(config=>{
            config.headers.Authorization = myStore.getStore('token')
            return config
        })

        vue.prototype.$http = axios1
    },
    
    http(){
        const axios1 = axios.create({
            baseURL:'http://120.24.182.152:8000/api/'
        })

        axios1.interceptors.request.use(config=>{
            config.headers.Authorization = myStore.getStore('token')
            return config
        })

       return axios1
    }
    
}
