import Vue from 'vue'
import infiniteScroll from "vue-infinite-scroll";
import {Button, Aside, Container, Main, Breadcrumb, BreadcrumbItem, Switch,Drawer,Table,TableColumn} from 'element-ui'
import {Input,Slider,Col,Row,Carousel,CarouselItem,Popconfirm,Dialog,Form,FormItem, Select, Option} from 'element-ui'
import {Upload, DatePicker,Notification} from 'element-ui'
import { vueBaberrage } from "vue-baberrage";


Vue.use(vueBaberrage);
Vue.use(Button)
Vue.use(Aside)
Vue.use(Container)
Vue.use(Main)
Vue.use(Breadcrumb)
Vue.use(BreadcrumbItem)
Vue.use(Switch)
Vue.use(Row)
Vue.use(Col)
Vue.use(Input)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Popconfirm)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(infiniteScroll)
Vue.use(Drawer)
Vue.use(Slider)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(Upload)
Vue.use(DatePicker)

// Vue.prototype.$showmessage = Notification.Notification()
Vue.prototype.$notify = Notification // 消息提示


export default Vue