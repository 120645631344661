export default {
    namespaced:true,
    state:{
        indexData:null
    },
    mutations:{
        SAVE_INDEX_DATA(state,value){
            state.indexData = value
        }
    },
    actions:{
        
    }
}