import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter)

// import Home from '../pages/home/home.vue'
// import index from '../pages/home/index/index.vue'
const Home = ()=>import('../pages/home/home.vue')
const login = ()=> import('../pages/login.vue')
const index = ()=>import('../pages/home/index/index.vue')
const share = ()=>import('../pages/home/share/share.vue')
const product = ()=>import('../pages/home/product/product.vue')
const productDefault =()=> import('../pages/home/product/productDefault.vue')
const shareVideo =()=> import('../pages/home/share/shareVidoe.vue')
const shareText =()=> import('../pages/home/share/shareText.vue')
const person =()=> import('../pages/home/share/personShare.vue')

const chat =()=> import('../pages/home/chat/chat.vue')
const videoDeault = () => import('../pages/home/share/videoDefault.vue')

const user = ()=> import('../pages/home/user/user.vue')
const userinfo =()=> import('../pages/home/user/userinfo.vue')
const userChat =()=> import('../pages/home/user/userChat.vue')

const friendship = ()=> import('../pages/about/friendship.vue')
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
// 	return originalPush.call(this, location).catch(err => err);
// }


const router = new VueRouter({
    routes:[
        {
            path:'/',
            redirect:'/home'
        },
        {
            path:'/home',
            component:Home,
            children:[
                {
                    path:'/',
                    redirect:'/home/index'
                },
                {
                    path:'index',
                    component:index,
                    meta:{title:'首页'}
                },
                {
                    path:'share',
                    component:share,
                    children:[
                        {
                            path:'/',
                            redirect:'person'
                        },
                        {
                            path:'person',
                            meta:{title:'个人分享'},
                            component:person
                        },
                        // {
                        //     path:'video',
                        //     component:shareVideo,
                        //     meta:{title:'个人分享,学习视频'},
                        // },
                        {
                            path:'text',
                            component:shareText,
                            meta:{title:'个人分享,学习笔记'}
                        }
                    ]
                },
                {
                    path:'video_list',
                    component:shareVideo,
                    meta:{title:'视频分享'}
                },
                {
                    path:'video_list/videoDefault',
                    component:videoDeault,
                    meta:{title:'视频详情'},
                    props({query}){
                        return {videoId:query.id}
                    }
                },
                {
                    path:'product',
                    component:product,
                    meta:{title:'项目作品'}
                },
                {
                    path:'product/detail',
                    component:productDefault,
                    meta:{title:'项目作品,项目详情'},
                    props({query}){
                        return {productId:query.id}
                    }
                },
                {
                    path:'chat',
                    component:chat,
                    meta:{title:'技术交流'}
                },
                {
                    path:'user',
                    component:user,
                    children:[
                        {
                            path:'/',
                            redirect:'userinfo'
                        },
                        {
                            path:'userinfo',
                            component:userinfo,
                            meta:{title:'用户中心'}
                        },
                        {
                            path:'userchat',
                            component:userChat,
                            meta:{title:'在线聊天'},
                            props({query}){
                                return {friendId:query.id}
                            }
                        }
                    ]
                },
                {
                    path:'friendship',
                    component:friendship,
                    meta:{title:'友情链接'}
                }
            ]
        },
        {
            path:'/login',
            component:login,
            meta:{title:'登录'}
        },
    ],
    linkActiveClass:'active_color'
})

router.beforeEach((to,from,next)=>{
    next()
})

router.afterEach((to,from)=>{
    let title = to.meta.title?to.meta.title:'XZQ个人博客'
    title = title.split(',')
    document.title = title[title.length-1]
})

export default router