<template>
  <div class="home-footer">
    
    <div class="pp">
      <a href="https://www.upyun.com/?utm_source=lianmeng&amp;utm_medium=referra">
      <img
        src="@/assets/img/logo5.png"
        align="absmiddle"
        width="60px"
        height="28px"
      />
    </a>
    <span style="font-size:14px; color:black;">提供加速服务</span>

      &nbsp;&nbsp;
      ICP备案
      <a href="https://beian.miit.gov.cn/" target="_blank"
        >粤ICP备2021146000号-1</a
      >
      <span v-show="!isAbout" @click="mouseoverShow">相关信息</span>
      <span v-show="isAbout" @click="mouseoutHidle">收起</span>

      <span style="width:80px; "> &nbsp;&nbsp;</span>
    </div>
    <div
      :class="
        isAbout
          ? 'show-about is-show'
          : isfirst
          ? 'show-about'
          : 'show-about is-hide'
      "
    >
      <div class="relaction">
        <a href="">隐私政策</a>
        <a href="">关于作者</a>
        <a href="">联系作者</a>
      </div>
      <div class="shoe-text">如有侵权，请联系作者删除</div>
      <div class="friend">
        <!-- <div class="title">友情链接</div> -->
        <div class="list">
          <div class="item">
            <a href="">
              <img src="../assets/img/logo-big.png" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isfirst: true,
      isAbout: false,
    };
  },
  methods: {
    mouseoverShow() {
      this.isfirst = false;
      this.isAbout = true;
    },

    mouseoutHidle() {
      this.isAbout = false;
    },
  },
};
</script>

<style lang='less' scoped>
.home-footer {
  width: 100%;
  text-align: center;
  z-index: 100;
  background: #f6f5f8;
  position: relative;

  .pp {
    height: 100%;
    line-height: 30px;
    text-align: center;

    span {
      display: inline-block;
      padding: 0 10px;
      color: #3d59ef;
    }
  }
}

.show-about {
  position: absolute;
  top: -120px;
  left: 10%;
  display: none;
  width: 80%;
  height: 120px;
  background: rgba(255, 255, 255, 0.85);
  padding: 10px;
  box-shadow: 1px 1px 1px rgb(25, 25, 25);
  z-index: 100;
  .relaction {
    width: 100%;
    text-align: center;
    a {
      display: inline-block;
      font-size: 18px;
      color: #3d59ef;
      padding: 0 10px;
    }
  }

  .shoe-text {
    padding: 5px;
    text-align: center;
    color: #919191;
    font-size: 14px;
  }

  .friend {
    width: 100%;
    padding: 5px;
    .title {
      color: #3d59ef;
    }

    .list {
      padding: 5px;
      display: flex;
      justify-content: center;
      .item {
        width: 60px;
        height: 40px;
        padding: 0 10px;

        img {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}

.is-show {
  z-index: 100;
  animation: startShow 0.5s linear;
  display: block;
  opacity: 1;
}
.is-hide {
  z-index: 100;
  animation: endShow 0.3s linear;
  display: block;
  opacity: 0;
}

// 动画
@keyframes startShow {
  0% {
    opacity: 0;
    top: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    top: -120px;
  }
}

@keyframes endShow {
  0% {
    opacity: 1;
    top: -120px;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    top: 0;
  }
}
</style>