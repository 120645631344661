import http from '../../utils/axios.js'

export default {
    namespaced:true,
    state:{
        videoList:null,
        cateList:null,
        categoryId:null
    },
    actions:{
      setCategoryData(context, value){
          const {videoList, cateId} = value
          context.commit('SET_VIDEOLIST', videoList)
          context.commit('SET_CATEGORY_ID', cateId)
      }
    },
    mutations:{
        SET_VIDEOLIST(state, value){
            state.videoList = value
        },
        SET_CATEGORYLIST(state, vale){
            state.cateList = vale
        },
        SET_CATEGORY_ID(state,value){
            state.categoryId = value
        }
    }
}