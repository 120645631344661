

// 获取本地数据
function getUser(name){
    return JSON.parse(localStorage.getItem(name))||[]
}

export default {
    namespaced:true,
    actions:{
        // 保存用户数据
        // save_user_info(context, value){
        //     window.localStorage.setItem('userinfo', JSON.stringify(value))
        //     context.commit('SAVE_USER_INFO',value)
        // },
        // 获取用户数据
        
    },
    mutations:{
        // 保存用户状态
        SAVE_USE5R_INFO(state,value){
            state.userInfo = value
        },

        // 删除用户信息
        REMOVE_USER_INFO(state,_){
            state.userInfo = null
        }

    },
    sdate:{
        userInfo:null
    },
    getters:{
      
    }
}