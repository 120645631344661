export default {
    namespaced:true,
    state:{
        personList:null,
        personQuery:null
    },
    mutations:{
        PUSH_PERSON(state,value){
            state.personList = value
        },
        SAVE_QUERY(state, value){
            state.personQuery = value
        }
    },
    actions:{
        saveLocalData(context, value){
            const {personList,personQuery} = value
            context.commit('PUSH_PERSON', personList)
            context.commit('SAVE_QUERY', personQuery)
        }
    },
    getters:{
        
    }
}