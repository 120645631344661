export default {
    namespaced:true,
    state:{
        playVidreId:null,
        userInfo:null
    },
    actions:{

    },
    mutations:{
        SET_VIDEO_ID(state,value){
            state.playVidreId = value
        },
    },
    getters:{

    }
}