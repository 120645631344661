<template>
  <div>
    <audio
      preload
      :ended="endNisuc"
      ref="audio_ref"
      :src="playMusicObj.music"
    ></audio>
    <div v-show="!isPlay" class="music-but" @click="showMusic = true">
      <i class="iconfont icon-yinle"></i>
    </div>
    <div v-show="isPlay" class="music-but not-bg" @click="showMusic = true">
      <img class="start-music" :src="playMusicObj.image" alt="" />
    </div>
    <div>
      <el-drawer
        :visible.sync="showMusic"
        :with-header="false"
        custom-class="side-show"
      >
        <div class="main">
          <div class="player">
            <div class="music-img">
              <div v-show="isPlay&&isLoading" class="load">
                <div class="b"></div>
              </div>
              <img :src="playMusicObj.image" alt="" />
            </div>
            <div class="music-data">
              <div class="main-data">
                <div class="music-info">
                  <div class="name">{{ playMusicObj.name }}</div>
                  <div class="auhtor">{{ playMusicObj.author }}</div>
                </div>
                <div class="position">
                  <div class="next" @click="nextMusic(-1)">
                    <i class="iconfont icon-shangyishou1"></i>
                  </div>
                  <div class="next big" @click="playMusic(playMusicIndex)">
                    <i v-show="!isPlay" class="iconfont icon-ai23"></i>
                    <i v-show="isPlay" class="iconfont icon-zanting"></i>
                  </div>
                  <div class="next" @click="nextMusic(+1)">
                    <i class="iconfont icon-xiayishou1"></i>
                  </div>
                </div>
              </div>
              <div class="music-time">
                <el-slider
                  tooltip-class="play-time"
                  :show-tooltip="false"
                  @change="setMusicTimeLen"
                  v-model="value1"
                ></el-slider>
              </div>
            </div>
          </div>

          <div class="music-list">
            <el-table :data="musicList" >
              <el-table-column prop="name"> </el-table-column>
              <el-table-column prop="author" width="70"></el-table-column>
              <el-table-column>
                <template scope="{row}">
                  <div>
                    <div class="play-music" @click="playMusic(row.index)">
                      <div
                        v-if="row.index === playMusicIndex && isPlay === true"
                      >
                        <i class="iconfont icon-zanting"></i>
                      </div>
                      <div v-else>
                        <i class="iconfont icon-ai23"></i>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
export default {
  mane: "music",
  data() {
    return {
      showMusic: false,
      value1: 0, // 歌曲进度条
      musicList: [], // 歌曲列表
      isPlay: false, // 是否播放歌曲
      playMusicIndex: null, // 播放歌曲的id
      isLoading:false, // 加载资源中
      playMusicObj: {
        id: 10086,
        image:
          "http://120.24.182.152:5555/s/static/s/image_files/users_image/nusic_default.png",
        name: "背景音乐",
        author: "",
        music: "#",
        time_len: "00:00:00",
      }, // 播放的歌曲
      updateTimer: null, // 更新音乐状态定时器
    };
  },
  mounted() {
    this.getMusicList();

    // 注册audio事件
    // this.$nextTick(()=>{
    //   // 音乐结束事件
    //   this.$refs.audio_ref.ended = ()=>{
    //     this.endNisuc()
    //   }
    // })
     this.$nextTick(() => {
       // 挂载开始请求资源事件
       this.$refs.audio_ref.addEventListener("canplaythrough", () => {
        this.successGetAudio();
      });
      // 挂载视频加载成功
      this.$refs.audio_ref.addEventListener("loadstart", () => {
        this.startGetAudio();
      });
    });
  },
  methods: {
    // 获取音乐列表
    async getMusicList() {
      const { data: res } = await this.$http.get("music_list/");
      if (res.code !== "200") return;
      this.musicList = res.data;
      // 预处理
      this.musicList.forEach((v, i) => {
        v["index"] = i;
      });
    },
    // 开始加载资源
    startGetAudio(){
      this.isLoading = true
    },

    // 获取音乐成功
    successGetAudio(){
      this.isLoading = false
    },

    // 开始/暂停
    playMusic(index) {
      if (index < 0) return;
      this.isPlay = !this.isPlay;
      this.playMusicIndex = index;
      this.musicList.forEach((v) => {
        if (v.index === this.playMusicIndex) {
          this.playMusicObj = v;
        }
      });
      // 控制音乐
      this.$nextTick(() => {
        this.ctrlMusic();
      });
    },
    // 下一首/上一首
    nextMusic(add) {
      this.value1 = 0;
      if (this.playMusicIndex + add < 0) {
        this.playMusicIndex = this.musicList.length - 1
      } else {
        if (this.playMusicIndex + add > this.musicList.length - 1) {
          this.playMusicIndex = 0;
        } else {
          this.playMusicIndex += add;
        }
      }

      this.isPlay = true;
      this.musicList.forEach((v) => {
        if (v.index === this.playMusicIndex) {
          this.playMusicObj = v;
        }
      });
      // 控制音乐
      this.$nextTick(() => {
        this.value1 = 0
        this.ctrlMusic();
      });
    },

    // 音乐·结束
    endNisuc() {
      clearInterval(this.updateTimer); // 关闭当前歌曲定时器
      this.value1 = 0
      this.nextMusic(1); // 开启下一首
    },

    // 控制音乐
    ctrlMusic() {
      if(this.isLoading){
        // 等待
        setTimeout(()=>{
          this.ctrlMusic()
        },100)
        return
      }
      this.$refs.audio_ref.pause();
      // 播放/暂停音乐
      if (this.isPlay) {
        this.$refs.audio_ref.play();
        this.changeMusicSdate();
      } else {
        this.$refs.audio_ref.pause();
      }
    },

    // 音乐播放时，循环更新音乐状态
    changeMusicSdate() {
      if (this.isPlay) {
        if (this.updateTimer) {
          clearInterval(this.updateTimer);
        }
        try {
          this.updateTimer = setInterval(() => {
            // currentTime 现在位置   duration 总时长
            // console.log(this.$refs.audio_ref.duration);
            // console.log(this.$refs.audio_ref.currentTime);
            let allTime = this.$refs.audio_ref.duration;
            let nowTime = this.$refs.audio_ref.currentTime;
            this.value1 = parseInt((nowTime / allTime) * 100);
            if (allTime === nowTime) {
              this.endNisuc();
            }
          }, 300);
        } catch (err) {}
      }
    },

    // 控制音乐进度
    setMusicTimeLen() {
      if (this.isPlay) {
        //
        this.changeMusicSdate();
        this.$refs.audio_ref.currentTime =
          (this.$refs.audio_ref.duration * this.value1) / 100;
      } else {
        this.value1 = 0;
      }
    },
  },
};
</script>

<style lang='less' scoped>
// 头部logo
.music-but {
  float: right;
  background: royalblue;
  font-size: 8px !important;
  padding: 3px;
  color: #fff;
  border-radius: 50%;
  transform: rotate(360deg);

  img {
    width: 21px;
    height: 21px;
    border-radius: 50%;
  }
}
.not-bg {
  background: #f6f5f8;
}

.start-music {
  animation: crollMusic 5s linear infinite;
  animation-delay: 1s;
}

@keyframes crollMusic {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// 音乐视图
.main {
  padding: 20px;
  position: relative;
  .player {
    height: 100px;
    // width: 100%;
    display: flex;
    padding-bottom: 20px;
    border-bottom: #c7c6c6 1px solid;
    .music-img {
      width: 100px;
      height: 80px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        z-index: 1001;
      }
      .load{
        position: absolute;
        top: 0;
        left: 10%;
        // transform: translate(-50%,-50%);
        // transform-origin: 40px 0;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background: rgb(196, 196, 196);
        overflow: hidden;
        animation: crollMusic 0.5s linear infinite;
        z-index: 1000;
        .b{
          width: 50%;
          height: 50%;
          background: #3d5aeb;
        }
      }
    }

    .music-data {
      display: flex;
      flex-direction: column;
      padding-top: 10px;
      .main-data {
        display: flex;
        flex-direction: row;

        .music-info {
          display: flex;
          flex-direction: column;
          width: 130px;

          .name {
            width: 100%;
            color: black;
              display: -webkit-box;
              // 不换行
              overflow: hidden;
              -webkit-box-orient: vertical;
              // 俩点S
              -webkit-line-clamp: 1;
          }
          .auhtor {
            color: rgb(110, 110, 110);
            font-size: 12px;
          }
        }

        .position {
          display: flex;
          width: 100px;
          padding: 0 5px;
          // align-items: center;
          font-size: 20px;
          .next {
            width: 24px;
            height: 24px;
            background: #3d5aeb;
            color: #fff;
            border-radius: 50%;
            text-align: center;
            line-height: 21px;
            margin-top: 3px;
          }
          .big {
            margin: 0 10px;
            width: 30px;
            height: 30px;
            line-height: 27px;
            i{
              font-size: 18px !important;
            }
          }
        }
      }

      .music-time {
        .play-time {
          margin: 5px 0 !important;
        }
      }
    }
  }
}

// 音乐·列表
.music-list{
  // position: absolute;
  // right: -17px;
  height: 80vh;
  overflow: auto;
}

// 音乐列表播放
.play-music {
  width: 26px;
  height: 26px;
  background: #3d5aeb;
  color: #fff;
  text-align: center;
  line-height: 26px;
  border-radius: 50%;
}
</style>