<template>
  <div class="home-header">
    <el-row>
      <!-- 面包屑 -->
      <el-col class="one" :span="20">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>XZQ的博客</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <!-- 功能区 -->
      <el-col class="login_but" :span="3">
        <!-- 登录 | 用户信息 -->
        <router-link v-show="!isLogin" class="tologin" to='/login' >登录/注册</router-link>
        <router-link v-show="isLogin" class="userinfo"  to="/home/user" >
          <div class="user-img">
            <img :src="userinfo.userimg" alt="" />
          </div>
          <div class="user-name">{{ userinfo.username }}</div>
        </router-link>
      </el-col>
      <el-col class="music_but" :span="1">
        <!-- 背景音乐 -->
        <myMusic />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import myMusic from "./music.vue";
import myStore from '../utils/localStore'

export default {
  name: "home-header",
  components: { myMusic },
  data() {
    return {
      isLogin: false,
      userinfo: {},
    };
  },
  mounted() {
    // 查看是否登录
    this.lookIsLogin()
    // 注册
    this.$bus.$on('islogin',this.lookIsLogin)
  },
 beforeDestroy() {
    // 注销
    this.$bus.$off('islogin')
 },
  methods: {
    toLogin() {
      this.$router.push("/login");
    },
    toUserInfo() {
      this.$router.push("/home/user");
    },
    // 实时检查用户是否登录
    lookIsLogin() {
      this.isLogin = myStore.getStore('userinfo')['id']?true:false
      this.userinfo = myStore.getStore('userinfo')
    },
  },
};
</script>

<style lang='less' scoped>
.home-header {
  // padding: 8px 20px;
  height: 32px;
  width: 97%;
  background: #f6f5f8;
  .one {
    padding: 8px 20px;
  }
  .login_but {
    height: 30px;
    line-height: 27px;
    padding: 3px 10px;
  }

  .music_but {
    height: 29px;
    padding: 4px 10px;
  }
}

// 登录/用户
.tologin {
  margin-right: 10px;
  height: 22px;
  font-size: 12px;
  text-align: right;
  width: 100%;
}

.userinfo {
  height: 30px;
  padding: 2px;
  .user-img {
    float: left;
    height: 24px;
    width: 30%;
    padding-right: 5px;
    text-align: right;
    img {
      height: 24px;
      width: 24px;
      background: #dadada;
    }
  }

  .user-name {
    float: left;
    height: 24px;
    line-height: 24px;
    width: 65%;
    font-size: 12px;
    color: rgb(100, 100, 100);
    display: -webkit-box;
    // 不换行
    overflow: hidden;
    -webkit-box-orient: vertical;
    // 俩点S
    -webkit-line-clamp: 1;
  }
}
</style>