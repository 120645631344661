import Vue from './utils/element'
import App from './App.vue'
import axios from './utils/axios'
import router from './router/index'
import io from './comment/weapp.socket.io'
import store from './store/index'
import './assets/css/theme/index.css'
import './assets/css/global.css'

Vue.config.productionTip = false
// Vue.prototype.socket = io('http://127.0.0.1:8081')

Vue.use(axios)

new Vue({
  router,
  store,
  render: h => h(App),
  created(){
    Vue.prototype.$bus = this
  }
}).$mount('#app')
