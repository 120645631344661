// 获取本地数据
function getStore(name){
    return JSON.parse(localStorage.getItem(name))||[]
}

// 存储本地数据
function setStore(name,item){
    localStorage.setItem(name,JSON.stringify(item))
}

export default {
    getStore,
    setStore
}